<template>
  <CRow
    ><!--v-if="permissionsData.view_menus"-->
    <CCol col="12"
      ><!--v-if="showTable"-->
      <CCard>
        <CCardHeader>
          <CRow>
            <CCol lg="4" class="card-header-with-button">
              <CSelect
                label="Site"
                placeholder="-- Please Select --"
                :options="siteData"
                :value.sync="selectedSite"
              >
                <!--
                :options="siteNameDropdown"
                :value.sync="selectedSite"
                @change="onChangeSite"
                :disabled="userHasSingleSite"
                -->
              </CSelect>
            </CCol>
            <CCol lg="2" class="card-header-with-button">
              <CSelect
                label="Timeframe"
                placeholder="-- Please Select --"
                :options="[
                  { value: 'This Month', label: 'This Month' },
                  { value: 'Last Month', label: 'Last Month' },
                  { value: 'This Week', label: 'This Week' },
                  { value: 'Last Week', label: 'Last Week' },
                  { value: 'This Year', label: 'This Year' },
                  { value: 'Last Year', label: 'Last Year' },
                ]"
                :value.sync="selectedTimeFrame"
              >
              </CSelect>
            </CCol>
            <CCol lg="2" class="card-header-with-button">
              <CInput label="From" type="date" v-model="dateFrom" />
            </CCol>
            <CCol lg="2" class="card-header-with-button">
              <CInput label="To" type="date" v-model="dateTo" />
            </CCol>
            <CCol
              lg="2"
              class="card-header-with-button"
              style="padding-top: 33px"
            >
              <CButton type="submit" color="primary" @click="onViewClick"
                >View</CButton
              >
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody
          ><!-- v-if="selectedSite != null" -->
          <CRow>
            <CCol lg="6">
              <h3>Financials At A Glance</h3>
              <CDataTable :items="financialData" striped :header="false" />
            </CCol>
            <CCol lg="6"> </CCol>
          </CRow>
          <CRow class="mt-4">
            <CCol lg="3">
              <h3>Detailed Transactions</h3>
            </CCol>
            <!-- <CCol lg="3">
              <CInput>
                <template #append>
                  <CButton color="primary">
                    <CIcon name="cil-magnifying-glass" /> Search
                  </CButton>
                </template>
              </CInput>
            </CCol> -->
          </CRow>
          <CRow>
            <CCol lg="12">
              <CDataTable
                tableFilter
                :items="detailTransactionData"
                striped
                clickable-rows
                @row-clicked="rowClicked"
                :items-per-page="10"
                :pagination="{ doubleArrows: false, align: 'center' }"
              />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import store from "../../store";
import constants from "../../data/Constants.js";
import DynamoDBService from "../../services/dynamodbservice.js";
import moment from "moment";
export default {
  name: "Reports",
  data() {
    return {
      siteData: [],
      selectedSite: "0",
      selectedTimeFrame: "This Month",
      dateFrom: "",
      dateTo: "",
      startDate: "",
      endDate: "",
      orderData: [],
      roleName: "",
      financialData: [
        {
          label: "Total # of Orders",
          value: "$0",
        },
        {
          label: "Total Sales",
          value: "$0",
        },
        {
          label: "Total Commission",
          value: "$0",
        },
        {
          label: "Total Delivery Fees",
          value: "$0",
        },
        {
          label: "Total Tips",
          value: "$0",
        },
        {
          label: "Total Restaurant Income",
          value: "$0",
        },
      ],
      detailTransactionData: [],
      allTransactions: [],

      totalOrders: 0,
      totalSales: 0,
      totalCommission: 0,
      totalDeliveryFee: 0,
      totalTips: 0,
      totalRestaurantIncome: 0,
    };
  },
  mounted: function () {
    this.getSiteItems();
    this.getReportDetails(this.selectedSite);
    this.roleName = store.state.currentUser.jogg_role;
  },
  methods: {
    rowClicked(item, index) {
      let detail = this.allTransactions[index];
      this.$router.push({ path: `reports/detail`, query: { selectedData: detail } });
    },
    onViewClick() {
      var tst = this.selectedSite;
      var test1 = this.selectedTimeFrame;
      var test11 = this.dateFrom;
      var test111 = this.dateTo;

      if (this.selectedSite == "") {
        this.$alert(constants.SITE_ID_VALIDATION, constants.INFORMATION);
        return;
      }
      if (this.dateTo != "" && this.dateFrom == "") {
        this.$alert("From date is empty", constants.INFORMATION);
        return;
      }
      if (this.dateTo == "" && this.dateFrom != "") {
        this.$alert("To date is empty", constants.INFORMATION);
        return;
      }

      if (this.dateTo != "" && this.dateFrom != "") {
        if (new Date(this.dateTo) < new Date(this.dateFrom)) {
          this.$alert(constants.DATEDIFF, constants.INFORMATION);
          return;
        }
      }

      this.getReportDetails(this.selectedSite);
    },
    getReportDetails(site_id) {
      switch (this.selectedTimeFrame.toUpperCase()) {
        case "THIS WEEK": //this week
          this.startDate = moment()
            //.subtract(1, "days")
            .startOf("isoWeek")
            .format("YYYY-MM-DD");
          this.endDate = moment()
            //.add(1, "days")
            .endOf("isoWeek")
            .format("YYYY-MM-DD");
          break;
        case "LAST WEEK": //last week
          this.startDate = moment()
            .subtract(1, "weeks")
            //.subtract(1, "days")
            .startOf("isoWeek")
            .format("YYYY-MM-DD");
          this.endDate = moment()
            .subtract(1, "weeks")
            //.add(1, "days")
            .endOf("isoWeek")
            .format("YYYY-MM-DD");
          break;
        case "LAST MONTH": //last month
          this.startDate = moment()
            .subtract(1, "month")
            .startOf("month")
            .format("YYYY-MM-DD");
          this.endDate = moment()
            .subtract(1, "month")
            .endOf("month")
            .format("YYYY-MM-DD");
          break;
        case "LAST YEAR": //last year
          this.startDate = moment()
            .subtract(1, "year")
            .startOf("year")
            .format("YYYY-MM-DD");
          this.endDate = moment()
            .subtract(1, "year")
            .endOf("year")
            .format("YYYY-MM-DD");
          break;
        case "THIS MONTH": //this month
          this.startDate = moment().startOf("month").format("YYYY-MM-DD");
          this.endDate = moment().endOf("month").format("YYYY-MM-DD");
          break;
        case "THIS YEAR": //this year
          this.startDate = moment().startOf("year").format("YYYY-MM-DD");
          this.endDate = moment().endOf("year").format("YYYY-MM-DD");
          break;
      }
      if (this.dateFrom != "") {
        this.startDate = this.dateFrom;
      }
      if (this.dateTo != "") {
        this.endDate = this.dateTo;
      }

      var that = this;
      const dynamoDBService = new DynamoDBService();
      this.credentials = dynamoDBService.getCredentials();
      var params = {};
      if (site_id == "0") {
        params = {
          TableName: constants.ORDERS_TABLE_NAME,
          FilterExpression: "order_date BETWEEN :v1 AND :v2",
          ExpressionAttributeValues: {
            ":v1": this.startDate + "T00:00:00",
            ":v2": this.endDate + "T23:59:59",
          },
        };
      } else {
        params = {
          TableName: constants.ORDERS_TABLE_NAME,
          FilterExpression: "site_id = :id AND order_date BETWEEN :v1 AND :v2",
          ExpressionAttributeValues: {
            ":id": site_id,
            ":v1": this.startDate + "T00:00:00",
            ":v2": this.endDate + "T23:59:59",
          },
        };
      }

      dynamoDBService.getAllItems(params).then(
        (data) => {
          if (data != undefined || data != null || data.Count > 0) {
            
            data.Items = data.Items.sort((a, b) =>
              b.order_date.localeCompare(a.order_date)
            );
            that.totalOrders = 0;
            that.totalSales = 0;
            that.totalCommission = 0;
            that.totalDeliveryFee = 0;
            that.totalTips = 0;
            that.totalRestaurantIncome = 0;
            that.detailTransactionData = [];
            that.orderData = data.Items;
            that.totalOrders = data.Count;

            that.allTransactions = [];

            data.Items.forEach((element) => {
              that.allTransactions.push(element);
              var subtotal = parseFloat(element.subtotal);
              var tax = (subtotal * parseFloat(element.taxrate)) / 100;
              var sales = subtotal + tax;
              that.totalSales = that.totalSales + sales;
              var joggComm = (parseFloat(element.joggrate) * sales) / 100;
              var stripeProcess = (sales * 2.9) / 100 + 0.3;
              that.totalRestaurantIncome =
                that.totalRestaurantIncome +
                (sales - (joggComm + stripeProcess));
              that.totalCommission =
                that.totalCommission + parseFloat(element.joggfee);
              that.totalDeliveryFee =
                that.totalDeliveryFee + parseFloat(element.delivamt);
              if (
                element.tip != undefined &&
                element.tip != null &&
                element.tip != ""
              ) {
                that.totalTips = that.totalTips + parseFloat(element.tip);
              }
              var temp = {
                "Order Id": element.order_id,
                Name: element.firstname + " " + element.lastname,
                Site: element.restaurant,
                Date:  moment.utc(element.order_date).local().format("L") + " " + moment.utc(element.order_date).local().format("LT"),
                "Order Total": "$" + sales.toFixed(2),
                "# of Items": element.menu_items.length,
                Status: element.order_status,
              };

              that.detailTransactionData.push(temp);
            });
            that.financialData = [];
            var temp = { label: "", value: "" };
            temp.label = "Total # of Orders";
            temp.value = that.totalOrders;
            that.financialData.push(temp);

            var temp = { label: "", value: "" };
            temp.label = "Total Sales";
            temp.value = "$" + that.totalSales.toFixed(2);
            that.financialData.push(temp);

            if (that.roleName == "admin") {
              var temp = { label: "", value: "" };
              temp.label = "Total Commission";
              temp.value = "$" + that.totalCommission.toFixed(2);
              that.financialData.push(temp);

              var temp = { label: "", value: "" };
              temp.label = "Total Delivery Fees";
              temp.value = "$" + that.totalDeliveryFee.toFixed(2);
              that.financialData.push(temp);

              var temp = { label: "", value: "" };
              temp.label = "Total Tips";
              temp.value = "$" + that.totalTips.toFixed(2);
              that.financialData.push(temp);
            }

            var temp = { label: "", value: "" };
            temp.label = "Total Restaurant Income";
            temp.value = "$" + that.totalRestaurantIncome.toFixed(2);
            that.financialData.push(temp);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    getSiteItems() {
      var that = this;
      var params = {
        TableName: constants.SITES_TABLE_NAME,
      };
      const dynamoDBService = new DynamoDBService();
      this.credentials = dynamoDBService.getCredentials();
      dynamoDBService.getAllItems(params).then(
        (data) => {
          if (data != undefined || data != null || data.Count > 0) {
            that.siteData = [];
            var temp = { value: 0, text: "" };
            temp.value = "0";
            temp.label = "-- All Sites --";
            that.siteData.push(temp);
            if (that.roleName == "admin") {
              data.Items.forEach((element) => {
                var temp = { value: 0, text: "" };
                temp.value = element.site_id;
                temp.label = element.site_name;
                that.siteData.push(temp);
              });
            } else {
              var currentUserSites = store.state.currentUser.sites;
              var splitSites = currentUserSites.split(",");

              data.Items.forEach((element) => {
                splitSites.forEach((element1) => {
                  if (element1 == element.site_id) {
                    var temp = { label: "", value: "" };
                    temp.label = element.site_name;
                    temp.value = element.site_id;
                    that.siteData.push(temp);
                  }
                });
              });
            }
          }
        },
        (error) => {
          console.log(error);
          this.$alert(error.message, constants.INFORMATION);
        }
      );
    },
    loadFinancialsAtAGlance() {
      return [
        {
          label: "Total # of Orders",
          value: "223",
        },
        {
          label: "Total Sales",
          value: "$3534.32",
        },
        {
          label: "Total Commission",
          value: "$587.34",
        },
        {
          label: "Total Delivery Fees",
          value: "$285.32",
        },
        {
          label: "Total Tips",
          value: "$285.32",
        },
        {
          label: "Total Restaurant Income",
          value: "$285.32",
        },
      ];
    },
  },
};
</script>
